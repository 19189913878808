import YouTube from 'react-youtube';
import { Helmet } from 'react-helmet';
import { useParams, useNavigate } from 'react-router-dom';
import { FaPlay, FaPause, FaHeart, FaRegHeart } from 'react-icons/fa';
import React, { useState, useEffect, useCallback, useRef } from 'react';

import styles from './SongView.module.css';

import ShareButtons from '../ShareButtons/ShareButtons';

import { isAuthenticated } from '../../services/authService';
import { getSongByArtistAndTitle, getAlbumSongs } from '../../services/api';

import { formatSlug } from '../../utils/stringUtils';

import FormattedLyrics from '../FormattedLyrics/FormattedLyrics';

function SongView({ initialSong }) {
  const { artist, title } = useParams();
  const navigate = useNavigate();
  const [song, setSong] = useState(initialSong || null);
  const [artistData, setArtistData] = useState(null);
  const [similarTracks, setSimilarTracks] = useState([]);
  const [loading, setLoading] = useState(!initialSong);
  const [error, setError] = useState(null);
  const [topTracks, setTopTracks] = useState([]);
  const [topAlbums, setTopAlbums] = useState([]);
  const [lyricsLoading, setLyricsLoading] = useState(false);
  const [similarTracksLoading, setSimilarTracksLoading] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const playerRef = useRef(null);
  const [playerReady, setPlayerReady] = useState(false);
  const [showScrollingPlay, setShowScrollingPlay] = useState(false);
  const lyricsRef = useRef(null);
  const initialPlayButtonRef = useRef(null);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      console.log(`Fetching song: ${artist} - ${title}`);
      const songResult = await getSongByArtistAndTitle(artist, title);
      console.log('Received song result:', songResult);

      if (songResult.exactMatch) {
        console.log('songResult.exactMatch', songResult.exactMatch);
        setSong(songResult.exactMatch);
        setSimilarTracks(songResult.similarTracks || []);
        setArtistData(songResult.artistData || null);
        setTopTracks(songResult.artistData?.topTracks || []);
        setTopAlbums(songResult.artistData?.topAlbums || []);

        if (!songResult.exactMatch.lyrics) {
          setLyricsLoading(true);
        }
        if (!songResult.similarTracks || songResult.similarTracks.length === 0) {
          setSimilarTracksLoading(true);
        }
      } else {
        console.warn('No exact match found');
        setError('Song not found.');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(`An unexpected error occurred: ${error.message}`);
    } finally {
      setLoading(false);
    }
  }, [artist, title]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const handleScroll = () => {
      if (lyricsRef.current && initialPlayButtonRef.current) {
        const lyricsRect = lyricsRef.current.getBoundingClientRect();
        const initialPlayButtonRect = initialPlayButtonRef.current.getBoundingClientRect();
        const scrollPosition = window.scrollY;

        const showThreshold = initialPlayButtonRect.top + scrollPosition - 50;
        const hideThreshold = lyricsRect.bottom - window.innerHeight;

        setShowScrollingPlay(scrollPosition > showThreshold && scrollPosition < hideThreshold);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handlePlayPause = useCallback(() => {
    if (playerRef.current && playerReady) {
      if (isPlaying) {
        playerRef.current.pauseVideo();
      } else {
        playerRef.current.playVideo();
      }
      setIsPlaying(!isPlaying);
    }
  }, [isPlaying, playerReady]);

  const handleCloseLoginModal = () => {
    setShowLoginModal(false);
  };

  const onPlayerReady = useCallback((event) => {
    playerRef.current = event.target;
    setPlayerReady(true);
  }, []);

  const handleFavoriteToggle = () => {
    if (isAuthenticated) {
      setIsFavorite(!isFavorite);
    } else {
      setShowLoginModal(true);
    }
  };

  const handleTagClick = (tag) => {
    navigate(`/tag/${encodeURIComponent(tag)}`);
  };

  const handleSimilarTrackClick = useCallback(
    (track) => {
      if (track && track.artist && track.title) {
        navigate(`/song/${formatSlug(track.artist)}/${formatSlug(track.title)}`);
      } else {
        console.error('Invalid track data:', track);
      }
    },
    [navigate]
  );

  const handleTopTrackClick = useCallback(
    (track) => {
      if (song && track && track.name) {
        navigate(`/song/${formatSlug(song.artist)}/${formatSlug(track.name)}`);
      } else {
        console.error('Invalid track data or song not loaded:', track);
      }
    },
    [navigate, song]
  );

  const handleArtistClick = useCallback(
    (artistName) => {
      navigate(`/artist/${encodeURIComponent(artistName)}`);
    },
    [navigate]
  );

  const handleTopAlbumClick = useCallback(
    async (album) => {
      if (song && album && album.name) {
        try {
          const albumData = await getAlbumSongs(song.artist, album.name);
          if (albumData) {
            navigate(`/album/${formatSlug(song.artist)}/${formatSlug(album.name)}`, {
              state: { data: albumData },
            });
          } else {
            console.error('No songs found for album:', album.name);
          }
        } catch (error) {
          console.error('Error fetching album songs:', error);
        }
      } else {
        console.error('Invalid album data or song not loaded:', album);
      }
    },
    [navigate, song]
  );

  if (loading) return <div className={styles.loading}>Loading...</div>;
  if (error) return <div className={styles.error}>{error}</div>;
  if (!song) return <div className={styles.noData}>No song data available</div>;

  const seoSchema = {
    '@context': 'https://schema.org',
    '@type': 'MusicComposition',
    name: song.title,
    composer: {
      '@type': 'Person',
      name: song.artist,
    },
    lyricist: {
      '@type': 'Person',
      name: song.artist,
    },
    musicCompositionForm: 'Song',
    musicalArtist: {
      '@type': 'MusicGroup',
      name: song.artist,
    },
    lyrics: {
      '@type': 'CreativeWork',
      text: song.lyrics,
    },
    video: {
      '@type': 'VideoObject',
      name: `${song.title} - ${song.artist}`,
      embedUrl: `https://www.youtube.com/watch?v=${song.videoId}`,
    },
  };

  return (
    <>
      <Helmet>
        <title>{`${song.title} by ${song.artist} | Singit Translation`}</title>
        <meta
          name='description'
          content={`Explore the lyrics and translation of "${song.title}" by ${song.artist}. Understand the meaning and learn the language with Singit Translation.`}
        />
        <script type='application/ld+json'>{JSON.stringify(seoSchema)}</script>
      </Helmet>
      <div className={styles.songView}>
        <div className={styles.header}>
          <div className={styles.coverArt}>
            <img
              src={song.thumbnailUrl || artistData?.imageUrl || '../../default-cover.jpg'}
              alt={`${song.title} cover`}
            />
          </div>
          <div className={styles.songInfo}>
            <h1 className={styles.H1Info}>
              <span className={styles.H1Span} onClick={() => handleArtistClick(song.artist)}>
                {song.artist}
              </span>{' '}
              - {song.title} Traducción al Español{' '}
            </h1>
            <div className={styles.songMeta}>
              <span>{song.HowManylisteners || '0'} listeners</span>
              {artistData && artistData.genre && (
                <span onClick={() => handleTagClick(artistData.genre)}>{artistData.genre}</span>
              )}
            </div>
            <div className={styles.actions}>
              <button className={styles.playButton} onClick={handlePlayPause}>
                {isPlaying ? <FaPause /> : <FaPlay />} {isPlaying ? 'Pause' : 'Play'}
              </button>
              <button className={styles.iconButton} onClick={handleFavoriteToggle}>
                {isFavorite ? <FaHeart /> : <FaRegHeart />}
              </button>
              <ShareButtons url={window.location.href} />
            </div>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.mainColumn}>
            <div className={styles.videoWrapper}>
              {song.videoId ? (
                <YouTube
                  videoId={song.videoId}
                  opts={{ height: '390', width: '640', playerVars: { autoplay: 0 } }}
                  onReady={onPlayerReady}
                  onPlay={() => setIsPlaying(true)}
                  onPause={() => setIsPlaying(false)}
                  onEnd={() => setIsPlaying(false)}
                />
              ) : (
                <p>We're sorry, but the video for this song is not available at the moment.</p>
              )}
            </div>

            <div className={styles.lyricsSection} ref={lyricsRef}>
              <div className={styles.lyricsHeader}>
                <div className={styles.lyricsTitleRow}>
                  <h3 className={styles.lyricsTitle}>
                    {song.title} by {song.artist} - Lyrics Traducción al Español
                  </h3>
                  <div className={styles.DivPlayButton}>
                    <button className={styles.lyricsPlayButton} onClick={handlePlayPause} ref={initialPlayButtonRef}>
                      {isPlaying ? <FaPause /> : <FaPlay />} {isPlaying ? 'Pause' : 'Play'}
                    </button>
                  </div>
                </div>
              </div>
              <hr />
              <div className={styles.lyricsContent}>
                <FormattedLyrics originalLyrics={song.lyrics} translatedLyrics={song.translatedLyrics} />
              </div>
            </div>
          </div>

          <div className={styles.sidebar}>
            {similarTracks.length > 0 && (
              <div className={styles.sidebarSection}>
                <h3>Similar Tracks</h3>
                <ul>
                  {similarTracks.map((track, index) => (
                    <li key={index} onClick={() => handleSimilarTrackClick(track)} className={styles.clickableItem}>
                      {track.artist} - {track.title}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {topTracks.length > 0 && (
              <div className={styles.sidebarSection}>
                <h3>Top Tracks by {song.artist}</h3>
                <ul>
                  {topTracks.map((track, index) => (
                    <li key={index} onClick={() => handleTopTrackClick(track)} className={styles.clickableItem}>
                      {track.name} - {track.playcount} plays
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {topAlbums.length > 0 && (
              <div className={styles.sidebarSection}>
                <h3>Top Albums by {song.artist}</h3>
                <ul>
                  {topAlbums.map((album, index) => (
                    <li key={index} onClick={() => handleTopAlbumClick(album)} className={styles.clickableItem}>
                      {album.name} - {album.playcount} plays
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      {showScrollingPlay && (
        <button className={styles.scrollingPlayButton} onClick={handlePlayPause}>
          {isPlaying ? <FaPause /> : <FaPlay />}
        </button>
      )}
    </>
  );
}

export default SongView;
