import axios from 'axios';

import { baseURL } from './api';

const AUTH_BASE_URL = `${baseURL}/auth`;

const api = axios.create({
  baseURL: AUTH_BASE_URL,
  withCredentials: true, // This is important for handling cookies if you're using them
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000; // 1 second

const retryRequest = async (fn, retries = 0) => {
  try {
    return await fn();
  } catch (error) {
    if (error.response && error.response.status === 429 && retries < MAX_RETRIES) {
      await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY * Math.pow(2, retries)));
      return retryRequest(fn, retries + 1);
    }
    throw error;
  }
};

export const loginWithGoogle = () => {
  window.location.href = `${AUTH_BASE_URL}/google`;
};

export const loginWithSpotify = () => {
  window.location.href = `${AUTH_BASE_URL}/spotify`;
};

export const handleOAuthCallback = async (token) => {
  if (!token) {
    throw new Error('No token provided');
  }
  localStorage.setItem('token', token);
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return checkAuthStatus();
};

export const logout = async () => {
  try {
    await api.post('/logout');
    localStorage.removeItem('token');
  } catch (error) {
    console.error('Logout error:', error);
    throw error;
  }
};

export const isAuthenticated = () => {
  return !!localStorage.getItem('token');
};

export const checkSubscription = async () => {
  try {
    const response = await api.get('/check-subscription');
    return response.data;
  } catch (error) {
    console.error('Error checking subscription:', error);
    throw error;
  }
};

export const subscribeUser = async (email) => {
  try {
    const response = await api.post('/subscribe', { email });
    // The response now includes both subscription data and auth status
    return response.data;
  } catch (error) {
    console.error('Subscription error:', error);
    throw error;
  }
};

export const checkAuthStatus = async () => {
  try {
    const subscribedEmail = localStorage.getItem('subscribedEmail');
    const token = localStorage.getItem('token');

    const config = {
      params: {},
      headers: {},
    };

    if (subscribedEmail) {
      config.params.email = subscribedEmail;
    }
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    const response = await api.get('/status', config);
    console.log('checkAuthStatus response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error checking auth status:', error);
    return {
      isAuthenticated: false,
      user: null,
      subscribedEmail: null,
      remainingSearches: 0,
    };
  }
};

export default api;
